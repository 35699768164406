import { WEB_ANALYTICS_CLUSTERGROUP } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Cluster Owner", "search-by": "ClusterName,OwnerId,OwnerName", "ph-search-by": "Search by keywords", "has-delete": true, "config-delete": _vm.configDel, "has-active": false, "has-filter-inactive": false, "custom-url": _vm.customUrl } }, [_c("text-field", { key: "ClusterName", attrs: { "data-index": "clusterName", "title": "Cluster", "sorter": false } }), _c("text-field", { key: "OwnerId", attrs: { "data-index": "ownerId", "title": "Owner ID", "sorter": false } }), _c("text-field", { key: "OwnerName", attrs: { "data-index": "ownerName", "title": "Owner Name", "sorter": false } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListClusterOwner",
  inject: ["apiUrl"],
  data() {
    return {
      customUrl: `${this.apiUrl}/web-analytics/clusters`,
      configDel: {
        source: "clusterName",
        sourceLabel: "Cluster"
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListClusterOwner = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-cluster-owner" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.clusters", "create-route": "/web-analytics/cluster-owner/create", "edit-route": "/web-analytics/cluster-owner/:id", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-cluster-owner")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListClusterOwner
  },
  data() {
    return {
      page: WEB_ANALYTICS_CLUSTERGROUP,
      apiUrl,
      itemsMenu: [
        {
          key: "clustermapping",
          title: "Cluster Mapping",
          path: ""
        },
        {
          key: "clusterowner",
          title: "Cluster Owner",
          path: "/web-analytics/cluster-owner"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
